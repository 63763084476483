export default {
  appName: (() => {
    const url = window.location.href;
    if (url.includes('www.aioffice.fun/zyls') || url.includes('zyls.aioffice.fun')) {
      return '智源论思';
    } else {
      return '文匠';
    }
  })(),
  // 其他的全局配置项...
}; 