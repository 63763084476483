import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: null,
    },
    getters: {
        getUserInfo: state => state.userInfo,
    },
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo;
        },
        CLEAR_USER_INFO(state) {
            state.userInfo = null;
        }
    },
    actions: {
        setUser(context, userInfoResp) {
            // 将 Java 对象转换为 JavaScript 对象
            const userInfo = {
                userId: userInfoResp.userId,
                name: userInfoResp.name,
                avatar: userInfoResp.avatar,
                accountValidity: userInfoResp.accountValidity,
                phone: userInfoResp.phone,
                userRole: userInfoResp.userRole,
            };

            context.commit('SET_USER_INFO', userInfo);
        },
        clearUser({commit}) {
            commit('CLEAR_USER_INFO');
        },
        logout({ commit }) {
            // 1. 清除 localStorage 中的用户信息
            localStorage.removeItem('userInfo');
            // 2. 提交 mutation，把 state 中的 userInfo 清空
            commit('SET_USER_INFO', null);
            // 3. 如果需要跳转到某个页面，可以在这里写
            // router.push({name:'xxxx'}); // 可选
        }
    },
    modules: {}
})
